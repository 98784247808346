/* eslint-disable */
import { createStore, combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import settingsStoreConfig from './reducers/configurations';

const createReducer = (initialState, handlers) => {
  return (state = initialState, action) => (handlers[action.type] && handlers[action.type](state, action)) || state;
};

const configReducer = createReducer(settingsStoreConfig.initialState, settingsStoreConfig.actions);

const rootReducer = combineReducers({
  configurations: configReducer,
});

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer)

export default () => {
  const store = createStore(persistedReducer, {}, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
  const persistor = persistStore(store);
  return { store, persistor };
};
