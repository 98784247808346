import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Paper, Box } from '@material-ui/core';
import { format, startOfToday } from 'date-fns';
import { useAuth0 } from '../../auth';
import axios from 'axios';
import config from '../../config';
import HistoryTable from './HistoryTable';

const HistoryPage = () => {
  const { getTokenSilently } = useAuth0();
  // global state
  const workspace = useSelector(state => state.configurations.workspace.uid);
  // local state
  const [pastRuns, setPastRuns] = useState([]);
  const [currentRuns, setCurrentRuns] = useState([]);
  const [startDate, setSelectedStartDate] = useState(startOfToday());
  const [endDate, setSelectedEndDate] = useState(startOfToday());
  const [allFiles, setAllFiles] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleStartDate = date => {
    setSelectedStartDate(date);
  };

  const handleEndDate = date => {
    setSelectedEndDate(date);
  };

  // get runs
  useEffect(() => {
    const fetchPastRuns = async () => {
      const token = await getTokenSilently();
      const start = format(startDate, 'yyyy-MM-dd');
      const end = format(endDate, 'yyyy-MM-dd');
      axios.get(`${config.api}/workspace/${workspace}/history?from=${start}&to=${end}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => {
          setPastRuns(res.data.reverse());
          setLoading(false);
        })
        .catch(err => console.log(err))
    }
    fetchPastRuns();
    return () => { };
  }, [endDate, getTokenSilently, startDate, workspace]);

  // get pending/running
  useEffect(() => {
    const fetchCurrentRuns = async () => {
      const token = await getTokenSilently();
      axios.get(`${config.api}/jobs`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => {
          setCurrentRuns(res.data.reverse());
        })
        .catch(err => console.log(err))
    }
    fetchCurrentRuns();
    return () => { };
  }, [getTokenSilently]);

  // get all files
  useEffect(() => {
    const getFiles = async () => {
      const token = await getTokenSilently();
      axios.get(`${config.api}/workspace/${workspace}/files`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
        .then(res => {
          setAllFiles(res.data);
        })
        .catch(err => console.log(err))
    };
    if (workspace) getFiles();
    return () => { };
  }, [getTokenSilently, workspace]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper style={{ height: '100%', display: 'flex', flexDirection: 'column', }}>
            <HistoryTable
              currentRuns={currentRuns}
              pastRuns={pastRuns}
              startDate={startDate}
              endDate={endDate}
              handleEndDate={handleEndDate}
              handleStartDate={handleStartDate}
              allFiles={allFiles}
              loading={loading}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default HistoryPage;
