import React from 'react';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Button, Typography, Box, Toolbar, TextField } from '@material-ui/core';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/CheckCircle';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableRow: {
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      background: '#f5f5f5',
    }
  },
}));

const UploadProcessForm = ({
  configList,
  handleFileProcess,
  handleFileUpload,
  selectedConfig,
  handleSetSelectedConfig,
  range,
  handleSetRange,
  fileUploadPercent,
  fileId,
  handleSetFileId,
}) => {
  const classes = useStyles();

  return (
    <Box>
      <Toolbar className={classes.toolbar}>
        <Typography component="h2" variant="h6">Upload and/or Process</Typography>
      </Toolbar>
      <Box p={2} pt={0} display="flex" justifyContent="space-between" flexDirection="column">
        <Box display="flex" flexDirection="column" mb={2}>
          <Box display="flex">
            {selectedConfig ?
              <CheckIcon fontSize="small" style={{ marginRight: 8, marginTop: 3, color: 'green', }} /> :
              <CheckIcon fontSize="small" style={{ marginRight: 8, marginTop: 3, color: 'gray', }} />
            }
            <Typography variant="subtitle1">Choose Config</Typography>
          </Box>
          <Autocomplete
            labelid="schema-label"
            id="auto-select"
            options={!configList ? [] : configList}
            getOptionLabel={option => option.name}
            onChange={(e, value) => handleSetSelectedConfig(value)}
            renderInput={params => (
              <TextField {...params} placeholder="Select config..." fullWidth />
            )}
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={2}>
          <Box display="flex">
            {fileUploadPercent === 100 ?
              <CheckIcon fontSize="small" style={{ marginRight: 8, marginTop: 3, color: 'green', }} /> :
              <CheckIcon fontSize="small" style={{ marginRight: 8, marginTop: 3, color: 'gray', }} />
            }
            <Typography variant="subtitle1">Choose File</Typography>
          </Box>
          <Button
            variant="contained"
            component="label"
            color="secondary"
            disabled={!selectedConfig || fileUploadPercent > 0}
          >
            Upload File
            <input
              type="file"
              style={{ display: "none", }}
              onChange={handleFileUpload}
            />
          </Button>
        </Box>
        <Box display="flex" flexDirection="column" mb={2}>
          <TextField
            name="File ID"
            variant="outlined"
            margin="dense"
            onChange={handleSetFileId}
            value={fileId}
            label="File ID"
            disabled={fileUploadPercent > 0}
            placeholder="Upload a file or paste an existing file ID here"
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={2}>
          <TextField
            name="Range (optional)"
            variant="outlined"
            margin="dense"
            onChange={handleSetRange}
            value={range}
            label="Range (optional)"
            placeholder="Book Page formatting: 1-5, 10, 427-"
          />
        </Box>
        <Box display="flex" flexDirection="column" mb={2}>
          <Button
            variant="contained"
            component="label"
            color="secondary"
            disabled={!fileId || !selectedConfig}
            onClick={handleFileProcess}
          >
            Process File with Config
          </Button>
        </Box>
      </Box>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} lightbackground store={ToastsStore} />
    </Box>
  );
};

export default UploadProcessForm;
