import React from 'react';
import uuid from 'uuid/v4';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  Checkbox,
  FormGroup,
  FormControlLabel,
  RadioGroup,
  FormLabel,
  Radio,
  Typography,
  Box
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

const Settings = ({
  allSources,
  allSchemas,
  handleName,
  handleSource,
  handleFormat,
  handleSchemaSelect,
  handleIsLiquidSyntax,
  handleAnonymousId,
  handleMaxEventsPerSecond,
  handleDelimiter,
  handleHasHeaderRow,
  handleRelativeRootPathJSON,
  name,
  format,
  delimiter,
  hasHeaderRow,
  isLiquidSyntax,
  relativeRootPathJSON,
  anonymousId,
  maxEventsPerSecond,
  source,
  event,
}) => {

  const sourcesList = allSources && allSources.length > 0
    && allSources.sort((a, b) => (a.name > b.name ? 1 : -1)).map(item => (
      <MenuItem
        key={uuid()}
        value={item.uid}>{item.name}<span style={{ visibility: 'hidden', }}></span>
      </MenuItem>
    )
    );

  return (
    <Box>
      <Typography variant="subtitle1">Config Settings</Typography>
      <TextField label="Config Name" name="name" value={name} margin="normal" fullWidth={true} onChange={e => handleName(e.target.value)} />
      <FormControl style={{ width: '100%', }}>
        <InputLabel id="select-label">Source</InputLabel>
        <Select
          name="source"
          labelId="select-label"
          id="source"
          value={source}
          onChange={e => handleSource(e.target.value)}
        >
          {sourcesList}
        </Select>
      </FormControl>
      <Box>
        {event &&
          <Box mt={1}>
            <InputLabel shrink id="schema-label">Schema</InputLabel>
          </Box>
        }
        <Autocomplete
          labelid="schema-label"
          id="auto-select"
          options={!allSchemas ? [] : allSchemas}
          getOptionLabel={option => option.event}
          onChange={(e, value) => handleSchemaSelect(e, value)}
          renderInput={params => (
            <TextField {...params} placeholder={event ? event : 'Schema'} margin={event ? 'dense' : 'normal'} fullWidth />
          )}
        />
      </Box>
      <FormGroup row>
        <FormControlLabel
          control={
            <Checkbox
              checked={isLiquidSyntax}
              onChange={e => handleIsLiquidSyntax(e.target.checked)}
              value="isLiquidSyntax"
              color="primary"
              name="isLiquidSyntax"
              type="checkbox"
            />
          }
          label="Use Liquid Syntax"
        />
        <TextField
          name="anonymousId"
          label="Anonymous ID"
          margin="normal"
          fullWidth={true}
          value={anonymousId}
          onChange={e => handleAnonymousId(e.target.value)}
          style={{ marginTop: 8, }}
        />
        <TextField
          name="maxEventsPerSecond"
          label="Max Events Per Second"
          type="number"
          value={maxEventsPerSecond}
          onChange={e => handleMaxEventsPerSecond(e.target.value)}
          style={{ marginTop: 8, }}
        />
      </FormGroup>
      <FormGroup row style={{ marginTop: 14, }}>
        <FormControl component="fieldset">
          <FormLabel component="legend">Format</FormLabel>
          <Box display="flex">
            <RadioGroup aria-label="format" name="format" value={format} onChange={e => handleFormat(e.target.value)} style={{ flexDirection: 'row', marginTop: 10, }}>
              <FormControlLabel value="Delimited" control={<Radio color="primary" />} label="Delimited" />
              <FormControlLabel value="JSON" control={<Radio color="primary" />} label="JSON" />
            </RadioGroup>
          </Box>
        </FormControl>
        {format === 'Delimited' &&
          <FormControl component="fieldset">
            <FormLabel component="legend">Delimited Options</FormLabel>
            <FormControlLabel
              control={
                <Checkbox
                  checked={hasHeaderRow}
                  onChange={e => handleHasHeaderRow(e.target.checked)}
                  value="hasHeaderRow"
                  color="primary"
                  name="hasHeaderRow"
                  type="checkbox"
                />
              }
              label="Has Header Row"
            />
            <Box display="flex">
              <FormControl>
                <FormLabel>Delimiter</FormLabel>
                <RadioGroup aria-label="delimiter" name="delimiter" value={delimiter} onChange={e => handleDelimiter(e.target.value)} style={{ flexDirection: 'row', marginTop: 10, }}>
                  <FormControlLabel value="," control={<Radio color="primary" />} label="Comma" />
                  <FormControlLabel value=" " control={<Radio color="primary" />} label="Space" />
                  <FormControlLabel value="\t" control={<Radio color="primary" />} label="Tab" />
                </RadioGroup>
                <TextField
                  name="other"
                  label="Other"
                  value={delimiter !== ' ' ? delimiter : ''}
                  onChange={e => handleDelimiter(e.target.value)}
                  style={{ width: '20%', }}
                />
              </FormControl>
            </Box>
          </FormControl>
        }
        {format === 'JSON' &&
          <FormControl component="fieldset">
            <FormLabel component="legend">JSON Options</FormLabel>
            <Box>
              <TextField
                name="relativeRootPathJSON"
                label="Relative Root Path"
                value={relativeRootPathJSON}
                onChange={e => handleRelativeRootPathJSON(e.target.value)}
              />
            </Box>
          </FormControl>
        }
      </FormGroup>
    </Box>
  );
};

export default Settings;
