import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { useAuth0 } from '../../auth';
import history from '../../utils/history';
import axios from 'axios';
import config from '../../config';
import { Grid, Paper, Box, Typography, Button } from '@material-ui/core';
import Settings from './Settings';
import Mapping from './Mapping';
import ConfigTest from './ConfigTest';
import { ToastsStore, ToastsContainer, ToastsContainerPosition } from 'react-toasts';
import SaveIcon from '@material-ui/icons/Save';
import BackIcon from '@material-ui/icons/ArrowBackIos';

const Update = () => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();
  const { id } = useParams();
  // global state
  const workspace = useSelector(state => state.configurations.workspace.uid);
  const allSchemas = useSelector(state => state.configurations.schemas);
  // local state
  const [allSources, setAllSources] = useState();
  const [loading, setLoading] = useState(false);
  const [configFields, setConfigFields] = useState({
    name: '',
    writeKey: '',
    source: '',
    event: '',
    format: 'Delimited',
    formatOptions: {
      delimiter: ',',
      hasHeaderRow: false,
      relativeRootPathJSON: '',
      isLiquidSyntax: false,
    },
    anonymousId: null,
    maxEventsPerSecond: 50,
    schema: {}
  });

  // get selected config from id param if it exists -- edit config
  useEffect(() => {
    const fetchConfig = async () => {
      setLoading(true);
      const token = await getTokenSilently();
      axios.get(`${config.api}/workspace/${workspace}/config/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })
        .then(res => {
          setConfigFields(res.data);
        })
        .catch(err => console.log(err))
        .finally(() => setLoading(false));
    }
    if (id) fetchConfig();
    return () => { };
  }, [dispatch, getTokenSilently, id, workspace]);

  // get all sources
  useEffect(() => {
    const getSources = async () => {
      const token = await getTokenSilently();
      const result = await axios(
        `${config.api}/make/v1/workspaces/${workspace}/sources`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      setAllSources(result.data.sources);
    };
    if (workspace) getSources();
    return () => { };
  }, [getTokenSilently, workspace]);

  const handleSubmit = async () => {
    const token = await getTokenSilently();
    axios.post(`${config.api}/workspace/${workspace}/config`, configFields, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        ToastsStore.success(`${res.data.name} was successfully saved!`);
        dispatch({ type: 'ADD_CONFIG', payload: configFields });
        setTimeout(() => {
          history.push('/configurations')
        }, 2000)
      })
      .catch(err => {
        ToastsStore.error('File was not saved');
      })
  };

  const handleName = name => {
    setConfigFields(prevState => ({ ...prevState, name }));
  };

  const handleFormat = format => {
    setConfigFields(prevState => ({ ...prevState, format }));
  };

  const handleDelimiter = delimiter => {
    setConfigFields(prevState => ({ ...prevState, formatOptions: { ...prevState.formatOptions, delimiter } }));
  };

  const handleSource = uid => {
    const source = allSources.find(source => source.uid === uid);
    if (source === undefined || source.config === undefined || source.config.writeKey === undefined) {
      return;
    }
    setConfigFields(prevState => ({ ...prevState, source: uid }));
    setConfigFields(prevState => ({ ...prevState, writeKey: source.config.writeKey }));
  };

  const handleHasHeaderRow = checked => {
    setConfigFields(prevState => ({ ...prevState, formatOptions: { ...prevState.formatOptions, hasHeaderRow: checked } }));
  };

  const handleIsLiquidSyntax = checked => {
    setConfigFields(prevState => ({ ...prevState, formatOptions: { ...prevState.formatOptions, isLiquidSyntax: checked } }));
  };

  const handleRelativeRootPathJSON = relativeRootPathJSON => {
    setConfigFields(prevState => ({ ...prevState, formatOptions: { ...prevState.formatOptions, relativeRootPathJSON } }));
  };

  const handleAnonymousId = anonymousId => {
    setConfigFields(prevState => ({ ...prevState, anonymousId }));
  };

  const handleMaxEventsPerSecond = maxEventsPerSecond => {
    setConfigFields(prevState => ({ ...prevState, maxEventsPerSecond: parseInt(maxEventsPerSecond, 10) }));
  };

  const handleSchemaSelect = (e, value) => {
    if (value) {
      setConfigFields(prevState => ({ ...prevState, event: value.event, schema: value.schema }));
    }
  };

  const handleSchemaMapping = schema => {
    setConfigFields(prevState => ({ ...prevState, schema }));
  };

  return (
    <Box>
      <Box display="flex" justifyContent="space-between" mb={2}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <BackIcon color="secondary" style={{ cursor: 'pointer', }} onClick={e => history.push('/configurations')} />
          <Typography component="h2" variant="h6">{id ? 'Edit Config' : 'Add Config'}</Typography>
        </Box>
        <Button color="secondary" variant="contained" size="small" onClick={handleSubmit} startIcon={<SaveIcon />}>
          {id ? 'Update' : 'Create'}
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={4}>
          <Paper style={{ padding: 16, }}>
            <Settings
              allSources={allSources}
              allSchemas={allSchemas}
              handleName={handleName}
              handleSource={handleSource}
              handleFormat={handleFormat}
              handleSchemaSelect={handleSchemaSelect}
              handleIsLiquidSyntax={handleIsLiquidSyntax}
              handleAnonymousId={handleAnonymousId}
              handleMaxEventsPerSecond={handleMaxEventsPerSecond}
              handleDelimiter={handleDelimiter}
              handleHasHeaderRow={handleHasHeaderRow}
              handleRelativeRootPathJSON={handleRelativeRootPathJSON}
              name={configFields && configFields.name}
              format={configFields.format}
              delimiter={configFields.formatOptions && configFields.formatOptions.delimiter}
              hasHeaderRow={configFields.formatOptions && configFields.formatOptions.hasHeaderRow}
              isLiquidSyntax={configFields.formatOptions && configFields.formatOptions.isLiquidSyntax}
              relativeRootPathJSON={configFields.formatOptions && configFields.formatOptions.relativeRootPathJSON}
              anonymousId={configFields.anonymousId}
              maxEventsPerSecond={configFields.maxEventsPerSecond}
              source={configFields && configFields.source}
              event={configFields && configFields.event}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={8}>
          <Paper>
            <Mapping
              handleSchemaMapping={handleSchemaMapping}
              selectedSchema={configFields.schema}
              loading={loading}
            />
          </Paper>
        </Grid>
        <Grid item xs={12} lg={12}>
          <Paper style={{ padding: 16, }}>
            <ConfigTest
              config={configFields}
            />
          </Paper>
        </Grid>
      </Grid>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
    </Box>
  );
}

export default Update;
