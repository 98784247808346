import React, { useState } from 'react';
import { useAuth0 } from '../../auth';
import axios from 'axios';
import envconfig from '../../config';
import {
  Box,
  Button,
  FormControl,
  TextField,
  Typography
} from '@material-ui/core';

const ConfigTest = ({ config }) => {
  const { getTokenSilently } = useAuth0();
  const [data, setData] = useState('');
  const [output, setOutput] = useState('');

  const handleSubmitTestData = async () => {
    const token = await getTokenSilently();
    axios.post(`${envconfig.api}/test`, { config, data }, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setOutput(JSON.stringify(res.data, null, '\t'));
      })
      .catch(err => {
        if (typeof err === 'object')
          setOutput(JSON.stringify(err, null, '\t'));
        else
          setOutput(err);
      })
  }

  return (
    <Box>
      <Typography variant="subtitle1">Test It Out!</Typography>
      <TextField
        label="Sample Data (Include Header row if applicable)"
        value={data}
        multiline
        fullWidth
        variant="outlined"
        rows={8}
        onChange={(e) => setData(e.target.value)}
      />
      <Button
        color="secondary"
        variant="contained"
        size="large"
        onClick={handleSubmitTestData}
        fullWidth
        style={{ margin: 6, }}
      >
        Generate Events From Sample Data
      </Button>
      <FormControl style={{ width: '100%', }}>
        <TextField
          label="Output"
          value={output}
          multiline
          readOnly
          fullWidth
          variant="outlined"
          rows={20}
        />
      </FormControl>
    </Box>
  );
};

export default ConfigTest;
