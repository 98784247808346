const config = {
  development: {
    api: 'https://api.piper-development.redventures.io',
    auth0: {
      domain: 'redventures-prod.auth0.com',
      clientId: 'leX9ZGe99eJmPIy6OZzzqNhPM5V9HNFO',
      redirectUri: 'http://localhost:3001/auth/login',
      audience: 'test-production-piper-api-resource',
    },
  },
  test: {
    api: 'https://api.piper-development.redventures.io',
    auth0: {
      domain: 'redventures-prod.auth0.com',
      clientId: 'leX9ZGe99eJmPIy6OZzzqNhPM5V9HNFO',
      redirectUri: 'https://piper-development.redventures.io/auth/login',
      audience: 'test-production-piper-api-resource',
    },
  },
  production: {
    api: 'https://api.piper.redventures.io',
    auth0: {
      domain: 'redventures-prod.auth0.com',
      clientId: 'm4SkQKXk2SINMp8CcyAMaabDq6ci03OF',
      redirectUri: 'https://piper.redventures.io/auth/login',
      audience: 'production-piper-api-resource',
    },
  },
};

export default process.env.REACT_APP_CUSTOM_NODE_ENV === 'test' ? config.test : config[process.env.NODE_ENV];
