import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import UploadProcessForm from './UploadProcessForm';
import Details from './Details';
import config from '../../config';
import axios from 'axios';
import { ToastsStore } from 'react-toasts';
import { useAuth0 } from '../../auth';

const UploadPage = () => {
  const { getTokenSilently } = useAuth0();
  // Global state
  const allConfigs = useSelector(state => state.configurations.allConfigs);
  const workspace = useSelector(state => state.configurations.workspace.uid);
  // Local state
  const [selectedConfig, setSelectedConfig] = useState('');
  const [fileId, setFileId] = useState('');
  const [range, setRange] = useState('');
  const [fileUploadPercent, setFileUploadPercent] = useState(0);

  const displayDetails = fileUploadPercent === 100 || selectedConfig;

  const configList = allConfigs && allConfigs.length > 0
    && allConfigs.sort((a, b) => (a.name > b.name ? 1 : -1)).map(item => {
      return {
        name: item.name,
        id: item.id,
      }
    });

  const handleSetRange = e => {
    setRange(e.target.value);
  };

  const handleSetFileId = e => {
    setFileId(e.target.value);
  };

  const handleFileUpload = async e => {
    setFileUploadPercent(1);
    try {
      const file = e.target.files[0];
      const token = await getTokenSilently();
      axios.get(`${config.api}/workspace/${workspace}/files/${file.name}/put-url`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
        .then(res => {
          const data = res.data;
          axios.put(data.url, file, {
            headers: {
              ...data.headers,
            },
            onUploadProgress: (progressEvent) => {
              setFileUploadPercent(Math.round(progressEvent.loaded / progressEvent.total * 100));
            },
            timeout: 30000,
            timeoutErrorMessage: 'timeout reached',
          })
            .then(res => {
              ToastsStore.success(`${file.name} was successfully uploaded!`);
              setFileUploadPercent(100);
              setFileId(data.fileId);
            })
            .catch(err => {
              ToastsStore.error(`Upload failed. ${err.message}`);
              setFileUploadPercent(0);
            })
        }).catch(err => {
          ToastsStore.error(`Upload failed. ${err.message}`);
          setFileUploadPercent(0);
        });
    } catch (err) {
      ToastsStore.error(`Upload failed. ${err.message}`);
      setFileUploadPercent(0);
    }
  };

  const handleFileProcess = async e => {
    const body = { configId: selectedConfig.id };
    if (range) body.range = range;
    const token = await getTokenSilently();
    axios.post(`${config.api}/workspace/${workspace}/files/${fileId}/process/async`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        ToastsStore.success(`File successfully queued for processing. Run ID: ${res.data}`);
        setSelectedConfig('');
      })
      .catch(err => {
        ToastsStore.error(`File failed to process.  Please check the history tab to get additional details.`);
        setFileUploadPercent(0);
        setSelectedConfig('');
      })
  };

  const handleSetSelectedConfig = value => {
    setSelectedConfig(value);
  };

  const handleDelete = () => {
    setFileUploadPercent(0);
    setFileId('');
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6} lg={4}>
        <Paper>
          <UploadProcessForm
            handleFileProcess={handleFileProcess}
            handleFileUpload={handleFileUpload}
            configList={configList}
            selectedConfig={selectedConfig}
            handleSetSelectedConfig={handleSetSelectedConfig}
            range={range}
            handleSetRange={handleSetRange}
            fileUploadPercent={fileUploadPercent}
            fileId={fileId}
            handleSetFileId={handleSetFileId}
          />
        </Paper>
      </Grid>
      {displayDetails &&
        <Grid item xs={12} md={6} lg={4}>
          <Paper>
            <Details
              fileUploadPercent={fileUploadPercent}
              handleDelete={handleDelete}
              selectedConfig={selectedConfig}
              fileId={fileId}
            />
          </Paper>
        </Grid>
      }
    </Grid>
  );
};

export default UploadPage;
