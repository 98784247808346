const sort = (array, sortColumn, sortDirection) => {
  const stabilizedThis = (array || []).map((el, index) => [el, index]);

  const descendingComparator = (a, b, property) => {
    if (b[property] < a[property]) {
      return -1;
    }
    if (b[property] > a[property]) {
      return 1;
    }
    return 0;
  };
  const comparator = sortDirection === 'desc'
    ? (a, b) => descendingComparator(a, b, sortColumn)
    : (a, b) => -descendingComparator(a, b, sortColumn);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  return stabilizedThis.map((el) => el[0]);
};

export default sort;
