import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import history from '../../utils/history';
import uuid from 'uuid/v4';
import axios from 'axios';
import config from '../../config';
import { useAuth0 } from '../../auth';
import { Grid, Typography, Paper, Box, CircularProgress, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  card: {
    height: 200,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  paper: {
    padding: 16,
    height: '100%',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      background: '#f5f5f5',
    }
  }
}));

const WorkspacePage = () => {
  const classes = useStyles();
  const { getTokenSilently } = useAuth0();
  const dispatch = useDispatch();
  // Local state
  const [allWorkspaces, setAllWorkspaces] = useState([]);
  const [loading, setLoading] = useState(true);

  const handleClick = (workspace) => {
    // set workspace in store
    dispatch({ type: 'ADD_ALL', payload: { name: 'workspace', value: workspace } });
    history.push('/configurations');
  };

  // get all workspaces
  useEffect(() => {
    const fetchData = async () => {
      const token = await getTokenSilently();
      const result = await axios(
        `${config.api}/make/v1/workspaces`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setAllWorkspaces(result.data.workspaces);
      setLoading(false);
    };
    fetchData();
    return () => { };
  }, [getTokenSilently])

  return (
    <Box>
      <Typography component="h2" variant="h6" gutterBottom>Workspaces</Typography>
      <Typography variant="subtitle1" color="secondary" gutterBottom>
        <Link
          color="secondary"
          href="https://piper.rvdocs.io/"
          target="_blank"
        >Don&apos;t see your workspace? Please read these docs on how to install Piper in Make.
        </Link>
      </Typography>
      <Grid container spacing={2}>
        {allWorkspaces.length > 0 && allWorkspaces.map(workspace => (
          <Grid item xs={12} sm={6} md={3} lg={3} key={uuid()}>
            <Paper onClick={() => handleClick(workspace)} className={classes.paper}>
              <Box key={uuid()} className={classes.card}>
                <Typography component="h2" variant="h6">{workspace.name}</Typography>
                {workspace.sourceUids && <Typography variant="subtitle2">{workspace.sourceUids.length} Sources</Typography>}
                {workspace.destinationUids && <Typography variant="subtitle2">{workspace.destinationUids.length} Destinations</Typography>}
              </Box>
            </Paper>
          </Grid>
        ))}
      </Grid>
      {loading &&
        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: 100, }}><CircularProgress thickness={5.0} color="secondary" /></Box>
      }
    </Box>
  )
};

export default WorkspacePage;
