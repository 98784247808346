import React from 'react';
import { Link } from 'react-router-dom';
import { Divider, Box, List, ListItem, ListItemText, ListItemIcon } from '@material-ui/core';
import RouterLink from '@material-ui/core/Link';
import { Settings, Publish, History, Work, Description } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  links: {
    color: '#6c6c6c',
    textDecoration: 'none',
    listStyle: 'none',
  },
  wrapper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  }
}))

const NavItems = () => {
  const classes = useStyles();

  return (
    <>
      <Divider />
      <Box className={classes.wrapper}>
        <List component="nav">
          <Link to='/configurations' className={classes.links}>
            <ListItem button>
              <ListItemIcon><Settings color="primary" /></ListItemIcon>
              <ListItemText primary='Configurations' />
            </ListItem>
          </Link>
          <Link to='/upload' className={classes.links}>
            <ListItem button>
              <ListItemIcon><Publish color="primary" /></ListItemIcon>
              <ListItemText primary='Upload' />
            </ListItem>
          </Link>
          <Link to='/history' className={classes.links}>
            <ListItem button>
              <ListItemIcon><History color="primary" /></ListItemIcon>
              <ListItemText primary='History' />
            </ListItem>
          </Link>
          <Link to='/' className={classes.links}>
            <ListItem button>
              <ListItemIcon><Work color="primary" /></ListItemIcon>
              <ListItemText primary='Workspaces' />
            </ListItem>
          </Link>
        </List>
        <List component="nav">
          <RouterLink href='https://docs.rvapps.io/products/v1/piper' target="_blank" style={{ textDecoration: 'none', }} className={classes.links}>
            <ListItem button>
              <ListItemIcon><Description color="primary" /></ListItemIcon>
              <ListItemText primary='Documentation' />
            </ListItem>
          </RouterLink>
        </List>
      </Box>
    </>
  );
};

export default NavItems;
