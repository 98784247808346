import { createMuiTheme } from '@material-ui/core/styles';
import blueGrey from '@material-ui/core/colors/blueGrey';
import lightBlue from '@material-ui/core/colors/lightBlue';

const theme = createMuiTheme({
  palette: {
    primary: {
      light: blueGrey[300],
      main: blueGrey[900],
      dark: blueGrey[700],
    },
    secondary: {
      light: lightBlue[700],
      main: lightBlue[800],
      dark: lightBlue[900],
    },
  },
});

export default theme;
