import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const Callback = () => {
  return (
    <Box display="flex" justifyContent="center" alignItems="center" style={{ height: 100, }}><CircularProgress thickness={5.0} style={{ color: '#0277bd', }} /></Box>
  );
};

export default Callback;
