import React from 'react';
import { Provider } from 'react-redux';
import createStore from './store';
import ReactDOM from 'react-dom';
import App from './App';
import { Auth0Provider } from "./auth";
import { PersistGate } from 'redux-persist/integration/react'
import config from "./config";
import history from './utils/history';

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl
      ? appState.targetUrl
      : '/'
  );
};

const { store, persistor } = createStore();

ReactDOM.render(
  <Auth0Provider
    domain={config.auth0.domain}
    client_id={config.auth0.clientId}
    redirect_uri={config.auth0.redirectUri}
    audience={config.auth0.audience}
    scope="email openid profile read:all write:all get:schemas get:sources get:workspaces read:schemas read:sources read:workspaces"
    onRedirectCallback={onRedirectCallback}
  >
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>
  </Auth0Provider>, document.getElementById('root'));
