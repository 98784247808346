import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import history from '../../utils/history';
import sort from '../../utils/tableSort';
import uuid from 'uuid/v4';
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableSortLabel,
  TablePagination,
  Box,
  CircularProgress,
  Toolbar,
  Typography
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  DatePicker,
} from '@material-ui/pickers';
import { makeStyles } from '@material-ui/styles';
import CheckIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import PendingIcon from '@material-ui/icons/CloudCircle';
import RunningIcon from '@material-ui/icons/PlayCircleFilled';

const useStyles = makeStyles(() => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableRow: {
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      background: '#f5f5f5',
    }
  },
  wrapper: {
    height: '100%',
    overflow: 'auto',
  },
  checkIcon: {
    color: 'green',
    marginRight: 8,
  },
  errorIcon: {
    color: 'red',
    marginRight: 8,
  },
  abortedIcon: {
    color: 'orange',
    marginRight: 8,
  },
  pendingIcon: {
    color: 'yellow',
    marginRight: 8,
  },
  runningIcon: {
    color: 'blue',
    marginRight: 8,
  },
}));

const HistoryTable = ({ currentRuns, pastRuns, startDate, endDate, handleStartDate, handleEndDate, allFiles, loading }) => {
  const classes = useStyles();
  // global state
  const configNames = useSelector(state => state.configurations.configNames);
  // local state
  const [sortDirection, setSortDirection] = useState('desc');
  const [sortColumn, setSortColumn] = useState('date');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const headers = [
    { id: 'status', label: 'Status' },
    { id: 'runId', label: 'Run ID' },
    { id: 'config', label: 'Config' },
    { id: 'file', label: 'File' },
    { id: 'successRecords', label: 'Successful Records' },
    { id: 'totalRecords', label: 'Total Records Read' },
    { id: 'date', label: 'Date' },
  ];
  const rows = pastRuns && (pastRuns || []).map(run => {
    const filteredName = configNames.map(item => item.metadata.configId === run.metadata.configId ? item.metadata.name : null);
    const configName = filteredName.find(item => item !== null) || '(anonymous)';
    const file = allFiles.find(item => item && item.metadata && item.metadata.fileId === run.metadata.fileId);
    const fileName = file ? file.metadata.name : '-';
    const date = new Date(run.metadata.runFinished);
    const dateString = date.toDateString();
    const formattedDate = dateString.replace(dateString.charAt(3), ', ');

    return {
      runId: run.metadata.runId,
      status: run.metadata.completed,
      config: configName,
      file: fileName,
      successRecords: run.metadata.successfulRows,
      totalRecords: run.metadata.totalRows,
      date: date.getTime(), // this is used for sorting purposes only
      formattedDate: formattedDate,
    };
  });

  const createSortHandler = (property) => (event) => {
    handleSort(event, property);
  };

  const handleSort = (event, property) => {
    const isAsc = sortColumn === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortColumn(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  return (
    <Box className={classes.wrapper}>
      <Toolbar className={classes.toolbar}>
        <Typography component="h2" variant="h6">Run History</Typography>
        <Box p={1}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <DatePicker
              label="Start Date"
              autoOk
              showTodayButton
              format="MM/dd/yyyy"
              value={startDate}
              onChange={handleStartDate}
              animateYearScrolling
              style={{ marginRight: 16, }}
            />
            <DatePicker
              label="End Date"
              autoOk
              showTodayButton
              format="MM/dd/yyyy"
              value={endDate}
              onChange={handleEndDate}
              animateYearScrolling
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Toolbar>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map(header =>
              <TableCell key={header.id} sortDirection={sortColumn === header.id ? sortDirection : false}>
                <TableSortLabel
                  active={sortColumn === header.id}
                  direction={sortColumn === header.id ? sortDirection : 'desc'}
                  onClick={createSortHandler(header.id)}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {currentRuns && Array.isArray(currentRuns) && currentRuns.map(run => (
            <TableRow key={uuid()} className={classes.tableRow} onClick={e => history.push(`/history/details/${run.metadata.runId}`)}>
              <TableCell component="th" scope="row">
                <Box display="flex" alignItems="center">
                  {run.metadata.status === 'Aborted' ? <ErrorIcon fontSize="small" className={classes.abortedIcon} /> : null}
                  {run.metadata.status === 'Running' ? <RunningIcon fontSize="small" className={classes.runningIcon} /> : null}
                  {run.metadata.status === 'Pending' ? <PendingIcon fontSize="small" className={classes.pendingIcon} /> : null}
                  {run.metadata.status}
                </Box>
              </TableCell>
              <TableCell component="th" scope="row">{run.metadata.runId}</TableCell>
              <TableCell component="th" scope="row">-</TableCell>
              <TableCell component="th" scope="row">-</TableCell>
              <TableCell component="th" scope="row">-</TableCell>
              <TableCell component="th" scope="row">-</TableCell>
              <TableCell component="th" scope="row">-</TableCell>
            </TableRow>
          ))}

          {sort(rows, sortColumn, sortDirection)
            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            .map(({ runId, status, config, file, successRecords, totalRecords, formattedDate }) => {
              let displayStatus;
              if (status === 'True' && successRecords === totalRecords) displayStatus = 'Succeeded';
              else if (status === 'True') displayStatus = 'Partial Fail';
              else displayStatus = 'Failed';

              return (
                <TableRow key={uuid()} className={classes.tableRow} onClick={e => history.push(`/history/details/${runId}`)}>
                  <TableCell component="th" scope="row">
                    <Box display="flex" alignItems="center">
                      {displayStatus === 'Succeeded' ?
                        <CheckIcon fontSize="small" className={classes.checkIcon} /> :
                        <ErrorIcon fontSize="small" className={classes.errorIcon} />}
                      {displayStatus}
                    </Box>
                  </TableCell>
                  <TableCell component="th" scope="row">{runId}</TableCell>
                  <TableCell component="th" scope="row">{config}</TableCell>
                  <TableCell component="th" scope="row">{file}</TableCell>
                  <TableCell component="th" scope="row">{successRecords}</TableCell>
                  <TableCell component="th" scope="row">{totalRecords}</TableCell>
                  <TableCell component="th" scope="row">{formattedDate}</TableCell>
                </TableRow>
              );
            }
            )}
        </TableBody>
      </Table>
      <TablePagination
        labelRowsPerPage="Runs per page:"
        rowsPerPageOptions={[10, 25, 50]}
        component="div"
        count={rows.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onChangePage={handleChangePage}
        onChangeRowsPerPage={handleChangeRowsPerPage}
      />
      {loading &&
        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: 100, }}><CircularProgress thickness={5.0} color="secondary" /></Box>
      }
    </Box>
  );
};

export default HistoryTable;
