import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import history from '../../utils/history';
import axios from 'axios';
import config from '../../config';
import { useAuth0 } from '../../auth';
import {
  Typography,
  TextField,
  Button,
  Grid,
  Paper,
  Box,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slide,
} from '@material-ui/core';
import BackIcon from '@material-ui/icons/ArrowBackIos';
import LoopIcon from '@material-ui/icons/Loop';
import DownloadIcon from '@material-ui/icons/GetApp';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Details = () => {
  const { getTokenSilently } = useAuth0();
  const { id } = useParams();
  // global state
  const workspace = useSelector(state => state.configurations.workspace.uid);
  // local state
  const [run, setRun] = useState({});
  const [reRunId, setReRunId] = useState('');
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [range, setRange] = useState('');

  const handleSetRange = e => {
    setRange(e.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // get selected run from id param
  useEffect(() => {
    const fetchRun = async () => {
      const token = await getTokenSilently();
      axios.get(`${config.api}/workspace/${workspace}/history/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(res => {
          setRun(res.data);
          setLoading(false);
        })
        .catch(err => console.log(err))
    }
    if (id) fetchRun();
    return () => { };
  }, [getTokenSilently, id, workspace]);

  // downloads detailed summary of run
  const handleDetails = () => {
    const fetchDetailedRun = async () => {
      const token = await getTokenSilently();
      axios.get(`${config.api}/workspace/${workspace}/history/${id}/detailed`, {
        headers: {
          Authorization: `Bearer ${token}`,
        }
      })
        .then(({ data }) => {
          const downloadUrl = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', `run_${id}.csv`); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
        })
        .catch(err => console.log(err))
    }
    fetchDetailedRun();
  };

  const handleRerun = async (fileId, configId, range) => {
    setOpen(false);
    const body = { configId };
    if (range) body.range = range;
    const token = await getTokenSilently();
    axios.post(`${config.api}/workspace/${workspace}/files/${fileId}/process/async`, body, {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`
      }
    })
      .then(res => {
        setReRunId(res.data);
        ToastsStore.success(`Run successfully queued for processing. Rerun ID: ${res.data}`);
        setTimeout(() => {
          history.push('/history');
        }, 2000);
      })
      .catch(err => {
        ToastsStore.error(`Run failed to process.`);
      })
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" style={{ height: 100, }}><CircularProgress thickness={5.0} style={{ color: '#0277bd', }} /></Box>
    );
  }

  return (
    <Box style={{ height: '100%', }}>
      <Box display="flex" alignItems="center" mb={2}>
        <BackIcon color="secondary" style={{ cursor: 'pointer', }} onClick={e => history.push('/history')} />
        <Typography component="h2" variant="subtitle2">Run {run.id}</Typography>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={7}>
          <Paper style={{ padding: 16, display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto', }}>
            <Typography variant="subtitle1" style={{ fontWeight: '900', }} gutterBottom>Results</Typography>
            <Box>Status: <Typography display="inline" variant="subtitle2" style={{ color: run.results.completed ? 'green' : 'red', }}>{run.results && run.results.completed ? 'Succeeded' : 'Failed'}</Typography></Box>
            <Box>Total Records Read: <Typography display="inline" variant="subtitle2">{run.results && run.results.totalRecordCount}</Typography></Box>
            <Box>Processed Records Count: <Typography display="inline" variant="subtitle2">{run.results && run.results.processedRecordCount}</Typography></Box>
            <Box>Successful Records Count: <Typography display="inline" variant="subtitle2">{run.results && run.results.successRecordCount}</Typography></Box>
            <Box>Error Records: <Typography display="inline" variant="subtitle2">
              {run.results && run.results.recordErrors && run.results.recordErrors.length > 0 ? `[ ${run.results.recordErrors.join(', ')} ]` : 'None'}</Typography>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper style={{ padding: 16, display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto', }}>
            <Typography variant="subtitle1" style={{ fontWeight: '900', }} gutterBottom>Files</Typography>
            <Box>File ID: <Typography display="inline" variant="subtitle2">{run.fileId}</Typography></Box>
            <Box>Config Name: <Typography display="inline" variant="subtitle2">{run.config && run.config.name}</Typography></Box>
            <Box>Config ID: <Typography display="inline" variant="subtitle2">{run.config && run.config.id}</Typography></Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Paper style={{ padding: 16, display: 'flex', flexDirection: 'column', height: '100%', overflow: 'auto', }}>
            <Typography variant="subtitle1" style={{ fontWeight: '900', }} gutterBottom>Settings</Typography>
            <Box>Event: <Typography display="inline" variant="subtitle2">{run.config && run.config.event}</Typography></Box>
            <Box>Source: <Typography display="inline" variant="subtitle2">{run.config && run.config.source}</Typography></Box>
            <Box>Format: <Typography display="inline" variant="subtitle2">{run.config && run.config.format}</Typography></Box>
          </Paper>
        </Grid>
        <Grid item xs={12} md={7}>
          <Button variant="contained" style={{ marginRight: 16, }} size="small" color="secondary" onClick={handleDetails} startIcon={<DownloadIcon />}>
            Download Summary
          </Button>
          <Button variant="contained" size="small" color="secondary" onClick={handleClickOpen} startIcon={<LoopIcon />}>
            Rerun
          </Button>
          <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
          >
            <DialogContent>
              <DialogContentText>
                Are you sure you want to rerun?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <TextField
                name="Range (optional)"
                variant="outlined"
                margin="dense"
                onChange={handleSetRange}
                defaultValue={range}
                label="Range (optional)"
                placeholder="Book Page formatting: 1-5, 10, 427-"
              />
              <Button onClick={() => handleRerun(run.fileId, run.config.id, range)} color="primary">
                Run
              </Button>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          {reRunId !== '' && <Box>Run ID of rerun: <Typography display="inline" variant="subtitle2">{reRunId}</Typography></Box>}
        </Grid>
      </Grid>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} lightbackground store={ToastsStore} />
    </Box>
  );
};

export default Details;
