import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Router, Switch, Route } from 'react-router-dom';
import axios from 'axios';
import config from './config';
import { Container, Box, CircularProgress } from '@material-ui/core';
import { makeStyles, ThemeProvider } from '@material-ui/core/styles';
import theme from './config/themes';
import CssBaseline from '@material-ui/core/CssBaseline';
import Nav from './components/navigation';
import PrivateRoute from './components/PrivateRoute';
import Workspaces from './components/workspaces';
import Configurations from './components/configurations';
import History from './components/history';
import Upload from './components/upload';
import Callback from './components/Callback';
import Update from './components/update';
import Details from './components/history/Details';
import NotFound from './components/NotFound';
import history from './utils/history';
import { useAuth0 } from './auth';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    height: '100vh',
    padding: theme.spacing(3),
    overflow: 'scroll'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(0),
    paddingRight: theme.spacing(0),
  },
  appBarSpacer: theme.mixins.toolbar,
}));

const App = () => {
  const classes = useStyles();
  const { getTokenSilently, loading, isAuthenticated } = useAuth0();
  const dispatch = useDispatch();
  const workspace = useSelector(state => state.configurations.workspace.uid);

  useEffect(() => {
    // get all schemas as soon as app mounts
    const fetchData = async () => {
      if (isAuthenticated) {
        const token = await getTokenSilently();
        axios.get(`${config.api}/make/v1/workspaces/${workspace}/schemas/all`,
          {
            headers: {
              Authorization: `Bearer ${token}`
            }
          })
          .then(res => {
            dispatch({ type: 'ADD_ALL', payload: { name: 'schemas', value: res.data.schemas } })
          })
      }
    };
    if (workspace) fetchData();
    return () => { };
  }, [dispatch, getTokenSilently, isAuthenticated, workspace])

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" style={{ height: 100, }}><CircularProgress thickness={5.0} style={{ color: '#0277bd', }} /></Box>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <div className={classes.root}>
        <CssBaseline />
        <Router history={history}>
          <Nav />
          <main className={classes.content}>
            <div className={classes.appBarSpacer} />
            <Container maxWidth="lg" className={classes.container}>
              <Switch>
                <Route exact path="/auth/login" component={Callback} />
                <PrivateRoute exact path='/' component={Workspaces} />
                <PrivateRoute exact path='/configurations' component={Configurations} />
                <PrivateRoute exact path='/configurations/add' component={Update} />
                <PrivateRoute exact path='/configurations/edit/:id' component={Update} />
                <PrivateRoute exact path="/upload" component={Upload} />
                <PrivateRoute exact path="/history" component={History} />
                <PrivateRoute exact path="/history/details/:id" component={Details} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Container>
          </main>
        </Router>
      </div>
    </ThemeProvider>
  );
}

export default App;
