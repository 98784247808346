import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useAuth0 } from '../../auth';
import config from '../../config';
import axios from 'axios';
import { Grid, Paper, Box } from '@material-ui/core';
import ConfigTable from './ConfigTable';

const ConfigPage = () => {
  const dispatch = useDispatch();
  const { getTokenSilently } = useAuth0();
  // global state
  const workspace = useSelector(state => state.configurations.workspace.uid);
  const configs = useSelector(state => state.configurations.configNames);
  // local state
  const [configArray, setConfigArray] = useState([]);
  const [loading, setLoading] = useState(true);

  // set all configs for selected workspace
  useEffect(() => {
    dispatch({
      type: 'ADD_ALL',
      payload: { name: 'configNames', value: {} }
    })
    const getAllConfigs = async () => {
      const token = await getTokenSilently();
      const result = await axios(
        `${config.api}/workspace/${workspace}/config`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      dispatch({
        type: 'ADD_ALL',
        payload: { name: 'configNames', value: result.data }
      })
    };

    getAllConfigs();
    return () => { };
  }, [dispatch, getTokenSilently, workspace])

  // loop through all configs by name and set config in array
  useEffect(() => {
    const fetchConfigs = async () => {
      if (configs.length > 0) {
        const token = await getTokenSilently();
        axios.all(configs.map(item => axios.get(`${config.api}/workspace/${workspace}/config/${item.metadata.configId}`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        })))
          .then(axios.spread(function (...responses) {
            setConfigArray([]);
            responses.forEach(res => setConfigArray(oldArray => [...oldArray, res.data]));
          }))
          .catch(err => console.log(err))
          .finally(() => setLoading(false));
      }
    }
    fetchConfigs();
    return () => { };
  }, [configs, getTokenSilently, workspace]);

  // add all configs to state
  useEffect(() => {
    dispatch({ type: 'ADD_ALL_CONFIGS', payload: configArray });
    return () => { };
  }, [configArray, dispatch]);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper style={{ height: '100%', display: 'flex', flexDirection: 'column', }}>
            <ConfigTable
              configArray={configArray}
              loading={loading}
            />
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
}

export default ConfigPage;
