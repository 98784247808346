const initialState = {
  configNames: [],
  allConfigs: [],
  config: {
    name: '',
    writeKey: '',
    event: '',
    source: '',
    format: 'Delimited',
    formatOptions: {
      hasHeaderRow: false,
      delimiter: ',',
      relativeRootPathJSON: '',
      isLiquidSyntax: false,
    },
    anonymousId: null,
    maxEventsPerSecond: 50,
    schema: {
      properties: {},
    },
  },
  schemas: [],
  workspace: '',
};

const addAll = (state, { payload }) => ({
  ...state,
  [payload.name]: payload.value,
});

const addAllConfigs = (state, { payload }) => ({
  ...state,
  allConfigs: payload,
});

const addConfig = (state, { payload }) => ({
  ...state,
  allConfigs: [...state.allConfigs, payload],
});

const actions = {
  ADD_ALL: addAll,
  ADD_ALL_CONFIGS: addAllConfigs,
  ADD_CONFIG: addConfig,
};

export default {
  initialState,
  actions,
};
