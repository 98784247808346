import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '../../auth';
import { Link } from 'react-router-dom';
import axios from 'axios';
import config from '../../config';
import history from '../../utils/history';
import sort from '../../utils/tableSort';
import { Table, TableHead, TableRow, TableCell, TableBody, TableSortLabel, CircularProgress, Box, Toolbar, Typography, Button, TextField, InputAdornment } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import SearchIcon from '@material-ui/icons/Search';
import { ToastsContainer, ToastsContainerPosition, ToastsStore } from 'react-toasts';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  toolbar: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  tableRow: {
    cursor: 'pointer',
    transition: '0.2s',
    '&:hover': {
      background: '#f5f5f5',
    }
  },
  wrapper: {
    height: '100%',
    overflow: 'auto',
  },
  addButton: {
    display: 'flex',
    alignItems: 'center',
    color: '#ffffff',
    textDecoration: 'none',
  },
}));

const ConfigTable = ({ configArray, loading }) => {
  const classes = useStyles();
  const { getTokenSilently } = useAuth0();
  // global state
  const workspace = useSelector(state => state.configurations.workspace.uid);
  // local state
  const [filteredConfigs, setFilteredConfigs] = useState([]);
  const [allSources, setAllSources] = useState([]);
  const [sortDirection, setSortDirection] = useState('asc');
  const [sortColumn, setSortColumn] = useState('name');

  const headers = [
    { id: 'name', label: 'Name' },
    { id: 'id', label: 'ID' },
    { id: 'event', label: 'Event' },
    { id: 'source', label: 'Source' }
  ];
  const rows = filteredConfigs && (filteredConfigs || []).map(config => {
    const source = allSources.find(source => source.uid === config.source);
    const sourceName = source && source.name ? source.name : config.source;
    return { id: config.id, name: config.name, event: config.event, source: sourceName };
  });

  useEffect(() => {
    setFilteredConfigs(configArray);
    return () => { };
  }, [configArray]);

  // get all sources
  useEffect(() => {
    const getSources = async () => {
      const token = await getTokenSilently();
      const result = await axios(
        `${config.api}/make/v1/workspaces/${workspace}/sources`,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
      setAllSources(result.data.sources);
    };
    if (workspace) getSources();
    return () => { };
  }, [getTokenSilently, workspace]);

  // filter table
  const handleFilter = e => {
    const { value } = e.target;
    setFilteredConfigs(configArray.filter(config => config.name.toLowerCase().includes(value.toLowerCase())));
  };

  const createSortHandler = (property) => (event) => {
    handleSort(event, property);
  };

  const handleSort = (event, property) => {
    const isAsc = sortColumn === property && sortDirection === 'asc';
    setSortDirection(isAsc ? 'desc' : 'asc');
    setSortColumn(property);
  };

  return (
    <Box className={classes.wrapper}>
      <Toolbar className={classes.toolbar} display="flex">
        <Typography component="h2" variant="h6">Configurations</Typography>
        <Box display="flex">
          <Box display={{ xs: 'none', sm: 'block' }} style={{ marginRight: 50, }}>
            <TextField
              id="input-with-icon-textfield"
              placeholder="Filter configs..."
              onChange={handleFilter}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon fontSize="small" />
                  </InputAdornment>
                ),
              }}
            />
          </Box>
          <Button variant="contained" size="small" color="secondary">
            <Link to={`/configurations/add`} className={classes.addButton}><AddIcon />
              <Box display={{ xs: 'none', md: "block", }}>add config</Box>
            </Link>
          </Button>
        </Box>
      </Toolbar>
      <Table stickyHeader>
        <TableHead>
          <TableRow>
            {headers.map(header =>
              <TableCell key={header.id} sortDirection={sortColumn === header.id ? sortDirection : false}>
                <TableSortLabel
                  active={sortColumn === header.id}
                  direction={sortColumn === header.id ? sortDirection : 'asc'}
                  onClick={createSortHandler(header.id)}
                >
                  {header.label}
                </TableSortLabel>
              </TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {sort(rows, sortColumn, sortDirection).map(({ id, name, event, source }) => (
            <TableRow key={id} className={classes.tableRow} onClick={e => history.push(`/configurations/edit/${id}`)}>
              <TableCell component="th" scope="row">{name}</TableCell>
              <TableCell component="th" scope="row">{id}</TableCell>
              <TableCell component="th" scope="row">{event}</TableCell>
              <TableCell component="th" scope="row">{source}</TableCell>
            </TableRow>
          ))
          }
        </TableBody>
      </Table>
      {loading &&
        <Box display="flex" justifyContent="center" alignItems="center" style={{ height: 100, }}><CircularProgress thickness={5.0} color="secondary" /></Box>
      }
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} store={ToastsStore} />
    </Box>
  );
};

export default ConfigTable;
