import React from 'react';
import { Box, Typography, LinearProgress } from '@material-ui/core';
import { ToastsContainerPosition, ToastsContainer, ToastsStore } from 'react-toasts';
import DeleteIcon from '@material-ui/icons/DeleteForever';

const Details = ({ fileUploadPercent, handleDelete, selectedConfig, fileId }) => {
  return (
    <Box p={2}>
      {selectedConfig &&
        <Box>
          <Typography variant="subtitle1" style={{ fontWeight: '900', }}>Config:</Typography>
          <Typography variant="subtitle2">{selectedConfig && selectedConfig.name}</Typography>
        </Box>
      }
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        {fileUploadPercent !== 0 &&
          <Box mt={2}>
            <Box display="flex" justifyContent="space-between">
              <Box>{fileUploadPercent === 100 ? 'File Uploaded' : 'File Uploading...'}</Box>
              <Box display="flex" alignItems="center">{fileUploadPercent}%
                {fileUploadPercent === 100 && <DeleteIcon onClick={handleDelete} style={{ color: 'red', cursor: 'pointer', }} fontSize="small" />}
              </Box>
            </Box>
            <LinearProgress variant="determinate" value={fileUploadPercent} color="secondary" style={{ height: 12, }} />
            {fileUploadPercent === 100 && <Box>{fileId}</Box>}
          </Box>
        }
      </Box>
      <ToastsContainer position={ToastsContainerPosition.BOTTOM_LEFT} lightbackground store={ToastsStore} />
    </Box>
  );
};

export default Details;
