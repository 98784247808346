import React from 'react';
import { Typography } from '@material-ui/core';

const NotFound = () => {
  return (
    <>
      <Typography component="h1" variant="h4">404</Typography>
      <Typography component="h2" variant="h6">PAGE NOT FOUND</Typography>
    </>
  );
};

export default NotFound;
